import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    //const theme = this.props.theme;
    return (
      <svg
        className="raw_logo"
        //version="1.1"
        //id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width="50%"
        height="50%"
        viewBox="0 0 2000 978"
        enable-background="new 0 0 2000 978"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
        M1241.000000,979.000000 
          C827.333435,979.000000 414.166809,979.000000 1.000166,979.000000 
          C1.000111,653.000122 1.000111,327.000275 1.000055,1.000298 
          C667.666443,1.000198 1334.332886,1.000198 2000.999512,1.000099 
          C2000.999634,326.999695 2000.999634,652.999390 2000.999756,978.999512 
          C1747.833374,979.000000 1494.666626,979.000000 1241.000000,979.000000 
        M1124.312256,752.000305 
          C1122.908081,752.333069 1121.505127,752.949036 1120.099731,752.954224 
          C1107.654053,752.999878 1094.890991,754.483398 1083.143555,749.414307 
          C1074.985962,745.894287 1067.458740,740.853943 1059.838257,736.185547 
          C1057.686890,734.867554 1056.876221,734.407654 1055.424194,736.958496 
          C1052.643921,741.842957 1049.584839,746.603699 1046.240967,751.119263 
          C1044.268433,753.783081 1044.581787,755.747559 1046.985474,757.387329 
          C1052.819458,761.367249 1058.376953,766.133240 1064.785156,768.817261 
          C1082.515625,776.243286 1100.958862,779.920898 1120.412231,777.096741 
          C1132.005981,775.413574 1142.888794,772.851807 1152.978760,766.183167 
          C1167.403931,756.649414 1172.924683,742.318542 1175.756226,726.719971 
          C1177.421753,717.544922 1176.919434,707.931396 1176.954102,698.513489 
          C1177.073242,666.021667 1177.000122,633.529236 1176.999512,601.036987 
          C1176.999512,599.870605 1177.130981,598.683655 1176.960327,597.542236 
          C1176.619263,595.260559 1175.758545,593.796082 1172.866211,593.923645 
          C1167.045654,594.180481 1161.197632,594.185913 1155.376709,593.932739 
          C1151.680420,593.771973 1150.734863,595.357422 1150.956665,598.678406 
          C1151.160522,601.730042 1151.000000,604.806030 1151.000000,609.017822 
          C1146.256104,605.642578 1142.751099,602.427124 1138.663696,600.367859 
          C1122.489746,592.219238 1105.306519,591.002930 1087.848511,594.697510 
          C1073.216553,597.794067 1060.442627,604.909363 1051.678101,617.152832 
          C1046.433105,624.479675 1042.027954,632.870483 1039.373047,641.442078 
          C1037.249878,648.297485 1038.203979,656.144226 1038.031250,663.556580 
          C1037.771606,674.703064 1041.403564,684.876099 1047.094971,694.182068 
          C1056.736450,709.946594 1071.131470,719.049744 1089.061768,723.101440 
          C1098.979126,725.342468 1108.911621,725.717407 1118.763794,723.827637 
          C1124.483032,722.730530 1130.110718,720.535706 1135.410522,718.048401 
          C1140.683228,715.573547 1145.494263,712.114685 1150.586060,709.040039 
          C1150.224609,727.367371 1147.622192,746.070435 1124.312256,752.000305 
        M886.500488,201.999588 
          C888.621033,199.876511 890.904053,197.890564 892.832947,195.605652 
          C899.742432,187.421173 904.357117,179.188004 900.298157,167.402130 
          C894.569763,150.769012 871.741943,147.291397 861.233887,158.113113 
          C834.344299,185.805359 807.066833,213.121124 779.921570,240.564743 
          C772.912720,247.650665 765.819458,254.653076 757.856995,262.600006 
          C760.575317,264.877930 763.191650,266.733490 765.408630,268.982758 
          C780.104431,283.892456 794.639648,298.960785 809.372131,313.833862 
          C826.613159,331.239410 844.071350,348.430023 861.290710,365.856842 
          C867.824524,372.469330 875.863770,373.522247 883.945801,371.357086 
          C899.766052,367.118774 910.272095,346.095764 894.509644,330.319611 
          C873.006897,308.798187 850.376526,288.403381 828.229614,267.525574 
          C828.999878,266.816833 829.770203,266.108093 830.540527,265.399353 
          C827.630676,266.722137 824.720886,268.044952 821.811035,269.367767 
          C821.462463,268.824554 821.113892,268.281311 820.765320,267.738098 
          C842.510254,245.992004 864.255188,224.245911 886.500488,201.999588 
        M1135.000000,301.500000 
          C1126.139038,310.692871 1117.395508,320.003021 1108.374634,329.036255 
          C1102.638428,334.780365 1100.036255,341.324982 1099.826050,349.525055 
          C1099.599121,358.383820 1106.741577,367.491089 1114.064819,370.539276 
          C1121.240234,373.525940 1133.617432,372.204803 1139.588867,366.086548 
          C1169.803467,335.129333 1200.336182,304.482727 1230.771240,273.740845 
          C1234.603516,269.869934 1238.516724,266.079163 1242.313599,262.329071 
          C1236.681885,256.167755 1231.628296,250.277832 1226.197998,244.758789 
          C1198.312256,216.417984 1170.385864,188.116913 1142.353882,159.920883 
          C1130.951782,148.452164 1114.390259,149.247742 1105.651611,159.820816 
          C1098.290527,168.727234 1097.686890,183.219635 1104.486938,190.899933 
          C1111.177612,198.456635 1118.268677,205.670837 1125.382690,212.838211 
          C1142.989990,230.577911 1160.723267,248.192596 1178.359497,265.903748 
          C1179.584839,267.134338 1180.428101,268.745300 1181.449097,270.179382 
          C1181.162842,270.393036 1180.876709,270.606689 1180.590454,270.820343 
          C1177.491699,268.973663 1174.392822,267.127014 1171.215942,265.233795 
          C1159.281250,277.185425 1147.390625,289.092743 1135.000000,301.500000 
        M997.960754,205.432053 
          C991.399231,220.393921 984.754028,235.319794 978.294067,250.325409 
          C966.135620,278.567749 954.192444,306.903229 941.938660,335.103882 
          C936.428345,347.785156 938.436340,357.837860 945.966003,365.178192 
          C958.367981,377.268311 978.025330,373.517365 984.718628,358.486298 
          C996.178955,332.749817 1006.889832,306.680573 1018.049133,280.808777 
          C1030.102783,252.863342 1042.333862,224.994492 1054.434204,197.069122 
          C1057.756226,189.402542 1062.124878,181.979645 1060.993042,173.094467 
          C1059.336792,160.092850 1048.814575,150.980133 1036.446899,151.800781 
          C1025.689087,152.514633 1018.271912,157.651413 1014.046021,167.599274 
          C1008.786438,179.980682 1003.378418,192.299042 997.960754,205.432053 
        M1377.499878,672.000000 
          C1383.662964,671.999939 1389.828979,671.886841 1395.987915,672.047852 
          C1399.044556,672.127747 1400.215332,670.990356 1400.026733,667.961304 
          C1399.607422,661.225647 1399.726440,654.433594 1398.922119,647.749268 
          C1397.045898,632.157349 1389.495361,619.167236 1378.073486,608.892578 
          C1370.217407,601.825500 1360.895142,596.598877 1350.106323,594.485046 
          C1335.348755,591.593628 1320.720947,591.763306 1307.055786,598.132141 
          C1284.324219,608.726562 1271.017578,627.178345 1268.263062,651.923645 
          C1266.319092,669.386597 1268.755493,686.320801 1279.636597,701.628845 
          C1289.468384,715.460571 1302.363037,724.311462 1318.042358,728.738831 
          C1331.362671,732.500061 1345.534790,731.944397 1358.842407,728.674988 
          C1371.776978,725.497253 1383.492188,718.814331 1391.690552,707.595520 
          C1392.407227,706.614868 1392.022583,703.989563 1391.175659,702.855530 
          C1387.960449,698.550476 1384.496704,694.396851 1380.755249,690.546753 
          C1379.973389,689.742249 1376.911377,689.772278 1376.090454,690.599304 
          C1367.223145,699.531860 1357.126587,704.931641 1344.187622,706.353699 
          C1334.829956,707.382202 1326.384521,705.543152 1318.208618,702.453369 
          C1304.592896,697.307800 1297.518921,686.192810 1294.029785,672.000000 
          C1321.638184,672.000000 1349.069092,672.000000 1377.499878,672.000000 
        M169.032501,681.362793 
          C173.604416,691.023438 179.689468,699.812195 187.583679,706.812439 
          C200.632843,718.383789 215.557663,726.237915 233.254730,729.313477 
          C247.820221,731.844849 262.185913,731.445557 276.207245,728.593140 
          C292.433533,725.292236 306.858093,717.480896 318.041077,704.576111 
          C320.116608,702.181030 320.579865,700.799255 318.086884,698.522278 
          C314.154419,694.930725 310.319763,691.189941 306.812317,687.189148 
          C304.177979,684.184387 302.548187,684.985657 299.990417,687.334290 
          C295.814728,691.168640 291.428528,694.898499 286.664001,697.940857 
          C276.786621,704.247986 265.386292,705.468567 254.073364,705.888977 
          C239.873688,706.416626 226.475403,703.072754 214.612839,694.889343 
          C204.158432,687.677307 195.945358,678.162292 191.417175,666.485291 
          C185.070831,650.119690 184.626877,633.120056 191.056992,616.465210 
          C195.144470,605.878052 201.639923,596.948792 210.584747,589.750244 
          C220.157562,582.046326 231.152725,578.175659 243.054840,575.913269 
          C254.999130,573.642822 266.381592,575.994690 277.593506,579.353821 
          C286.739685,582.093994 294.385284,587.593933 301.116852,594.420715 
          C301.938354,595.253845 304.926666,595.280273 305.836060,594.471741 
          C310.309967,590.494141 314.565704,586.236694 318.537201,581.757751 
          C319.322784,580.871765 319.193359,577.987366 318.347717,577.031738 
          C312.137329,570.013489 304.853241,564.167114 296.385376,560.151855 
          C277.743896,551.312561 258.156677,547.648621 237.619858,551.054993 
          C225.864822,553.004761 214.861023,557.022949 204.293900,562.803467 
          C193.701187,568.597961 185.297958,576.726624 178.055435,585.850647 
          C172.778809,592.498047 168.702011,600.405884 165.502075,608.322388 
          C160.565414,620.535645 159.139526,633.533936 159.968597,646.756226 
          C160.714920,658.658630 163.300323,670.020142 169.032501,681.362793 
        M435.973938,610.454590 
          C433.940643,608.427917 432.205475,605.902222 429.814667,604.474060 
          C424.070068,601.042419 418.330017,596.977661 412.018951,595.261963 
          C401.759094,592.472900 391.125305,592.573120 380.358948,593.810669 
          C363.830902,595.710632 349.012787,600.942871 336.421783,611.934509 
          C335.648956,612.609192 334.789246,614.370667 335.127747,614.949829 
          C338.744415,621.137878 342.594879,627.189270 346.526215,633.511536 
          C348.344025,632.290833 349.740540,631.490845 350.982574,630.497742 
          C363.891235,620.175964 378.744659,616.426208 395.042145,616.946167 
          C407.382233,617.339966 417.128967,624.523438 419.977966,636.361694 
          C420.944122,640.376282 421.070221,644.593079 421.615143,649.000000 
          C416.671600,649.000000 411.852173,648.889832 407.039307,649.020386 
          C391.949463,649.429688 376.763275,647.636108 361.865204,651.947815 
          C334.876862,659.758728 324.159943,687.574585 338.105286,710.160706 
          C344.622559,720.716248 354.811523,726.127747 366.818817,729.208435 
          C376.852203,731.782715 386.802826,731.335144 396.523499,729.681824 
          C403.533844,728.489441 410.064270,724.469238 416.804596,721.700256 
          C418.368286,721.057922 419.915283,720.374878 422.000000,719.484009 
          C422.000000,721.567017 422.166443,722.908997 421.970032,724.195618 
          C421.265411,728.811157 423.031891,730.501038 427.752869,730.088196 
          C432.382599,729.683289 437.111969,729.634888 441.731049,730.096313 
          C447.010162,730.623657 448.098724,728.263855 448.068695,723.592957 
          C447.918579,700.268066 448.064178,676.941345 447.954315,653.616028 
          C447.927277,647.872986 447.771484,642.065125 446.900208,636.406372 
          C445.478119,627.169983 443.304047,618.079468 435.973938,610.454590 
        M1681.972412,722.545349 
          C1683.762451,721.295532 1685.552490,720.045715 1688.000000,718.336792 
          C1688.000000,721.027954 1688.067139,722.815918 1687.986084,724.597107 
          C1687.816772,728.321472 1688.946777,730.385925 1693.272339,730.078308 
          C1698.079468,729.736389 1702.966064,729.630798 1707.751831,730.098694 
          C1713.059937,730.617676 1714.091431,728.223022 1714.067627,723.572632 
          C1713.929199,696.583252 1714.208374,669.589905 1713.843506,642.604736 
          C1713.759521,636.401917 1712.630737,629.867737 1710.394165,624.102417 
          C1702.734985,604.359375 1685.863159,594.472046 1665.824951,593.093689 
          C1653.541382,592.248779 1641.291870,593.403076 1629.439453,597.042725 
          C1619.981323,599.947083 1611.178711,604.191711 1603.662598,610.809631 
          C1601.299072,612.890686 1600.062134,614.442993 1602.797241,617.677124 
          C1606.079590,621.558289 1608.444336,626.259766 1610.911377,630.767456 
          C1612.136475,633.005981 1613.347412,633.910767 1615.367920,632.059326 
          C1624.865723,623.356873 1636.310303,618.571655 1648.830566,617.319763 
          C1655.992920,616.603638 1663.789551,617.064636 1670.610596,619.199829 
          C1683.919312,623.365906 1688.637451,634.209534 1686.527466,649.000000 
          C1684.970459,649.000000 1683.338379,648.968201 1681.707886,649.004944 
          C1667.296875,649.329590 1652.872314,649.381226 1638.479980,650.089722 
          C1626.896729,650.659912 1617.062134,655.212158 1608.604126,663.610291 
          C1596.197998,675.928528 1595.189209,694.472717 1602.501465,707.672424 
          C1611.275024,723.510010 1626.456421,729.982849 1643.885742,730.877502 
          C1656.930542,731.547058 1669.761841,729.815002 1681.972412,722.545349 
        M650.487671,614.008362 
          C641.396484,623.081848 636.523254,634.050232 633.743591,646.617310 
          C631.143555,658.372253 631.914856,669.744263 634.644775,680.977112 
          C637.795227,693.940002 645.061279,704.549622 655.007446,713.767761 
          C667.639343,725.475220 682.545166,730.573181 699.126465,730.815369 
          C710.389893,730.979919 721.512268,729.723083 732.100708,724.244995 
          C742.248779,718.994751 750.732727,712.257629 757.270447,703.162903 
          C767.747253,688.588562 771.852966,672.263428 770.073181,654.290466 
          C768.446228,637.860229 762.170105,623.699585 750.526428,612.119629 
          C739.422424,601.076477 725.781006,594.483276 710.247192,593.141785 
          C687.685852,591.193420 667.130005,596.206116 650.487671,614.008362 
        M1471.363770,707.000000 
          C1466.202271,706.977600 1460.772583,707.987061 1455.932495,706.728455 
          C1444.067993,703.643372 1431.992432,700.707947 1422.012329,692.761292 
          C1421.298340,692.192810 1418.348145,693.041687 1417.673340,694.054688 
          C1414.654907,698.586121 1412.389160,703.631409 1409.252075,708.067078 
          C1406.923584,711.359436 1408.140747,713.112610 1410.709106,714.970337 
          C1426.178833,726.159363 1443.917114,730.125610 1462.544922,730.915283 
          C1467.768799,731.136719 1473.032593,730.139771 1478.284180,730.042908 
          C1487.456177,729.873779 1495.786499,726.636108 1503.263550,721.988281 
          C1516.908936,713.506042 1523.082031,701.078247 1521.108154,684.645813 
          C1519.828491,673.992981 1514.300171,666.394348 1505.533936,661.330322 
          C1494.146484,654.752136 1481.219360,652.586365 1468.491211,649.895569 
          C1460.859375,648.282043 1453.207764,646.226501 1445.993774,643.309204 
          C1437.006348,639.674805 1437.242188,627.535767 1444.981323,621.997070 
          C1455.430420,614.518921 1467.351196,616.627258 1478.743286,617.535583 
          C1488.896973,618.345154 1497.941162,623.317627 1506.554321,629.438354 
          C1509.829468,623.699036 1512.737305,618.167664 1516.072144,612.906982 
          C1518.451660,609.153442 1518.490234,606.458496 1514.071045,604.549194 
          C1513.037598,604.102783 1512.080566,603.482056 1511.056274,603.011536 
          C1495.975220,596.082825 1480.314331,592.093811 1463.536255,592.941467 
          C1452.906616,593.478516 1443.010620,595.703796 1433.434448,600.768066 
          C1415.364014,610.324524 1410.185791,627.492004 1414.391113,644.303406 
          C1418.031372,658.855591 1430.006836,666.158936 1443.625488,669.833008 
          C1456.341431,673.263611 1469.479248,675.099792 1482.319336,678.115234 
          C1487.550659,679.343872 1493.315430,681.110291 1494.891235,687.279724 
          C1496.662109,694.212463 1493.488525,700.752136 1486.770508,703.509583 
          C1482.239380,705.369446 1477.155884,705.883789 1471.363770,707.000000 
        M1806.591187,677.997314 
          C1813.939575,678.707642 1819.892212,683.559448 1820.933105,689.687195 
          C1821.711426,694.269348 1817.435303,700.890198 1811.703369,703.697998 
          C1801.947144,708.477051 1791.392944,707.936462 1781.300171,706.453918 
          C1769.088501,704.660217 1757.470337,700.268494 1747.204224,692.916321 
          C1746.327271,692.288269 1743.548340,692.639465 1743.058350,693.419067 
          C1739.814697,698.580017 1737.040161,704.032715 1733.932251,709.283569 
          C1732.519043,711.671265 1733.177856,712.511902 1735.345703,714.241333 
          C1750.652222,726.452942 1768.700806,730.036438 1787.465088,730.836365 
          C1795.081421,731.161072 1802.851807,730.131165 1810.414795,728.884460 
          C1820.195923,727.272034 1828.934204,723.083618 1836.026489,715.879150 
          C1844.851196,706.914734 1847.940308,696.335571 1846.090210,683.871216 
          C1844.251709,671.485168 1836.796753,663.916321 1826.098999,659.037598 
          C1811.331909,652.303162 1795.148926,650.788452 1779.610229,646.880981 
          C1774.429810,645.578308 1768.972412,644.118103 1765.905640,638.710876 
          C1761.395630,630.759460 1766.583862,621.848511 1775.222046,619.095886 
          C1786.542847,615.488403 1798.197998,615.629150 1809.464233,619.073486 
          C1817.140015,621.420044 1824.377930,625.199402 1832.329712,628.556946 
          C1835.441040,622.815735 1838.923340,616.669983 1842.047607,610.347290 
          C1842.564575,609.300964 1841.982544,606.637207 1841.113892,606.156372 
          C1835.796875,603.212891 1830.454956,600.086853 1824.741577,598.130859 
          C1809.548096,592.929443 1793.918579,591.573242 1777.936523,593.940308 
          C1767.791138,595.442871 1758.613159,599.308350 1750.954468,605.773315 
          C1741.060913,614.125183 1737.701538,625.148621 1738.721313,638.286621 
          C1739.367920,646.617188 1742.508423,652.941833 1748.023682,658.452026 
          C1758.849609,669.267639 1773.363037,671.033691 1787.354492,674.003174 
          C1793.491089,675.305603 1799.620239,676.642822 1806.591187,677.997314 
        M848.492188,676.999451 
          C850.065247,677.333435 851.628845,677.722778 853.212952,677.991577 
          C859.214966,679.010437 865.187500,684.517029 865.970642,689.801575 
          C866.568848,693.837646 861.654053,702.094849 857.220093,703.891357 
          C847.194580,707.953369 836.557861,708.115540 826.241150,706.472229 
          C814.036377,704.528320 802.402405,700.271057 792.157471,692.869141 
          C791.306213,692.254150 788.526672,692.673462 788.023743,693.476685 
          C784.789185,698.642456 781.857849,704.006531 779.034668,709.413940 
          C778.488708,710.459595 778.304199,712.717102 778.898987,713.168213 
          C782.600403,715.975342 786.336548,718.920288 790.498291,720.901062 
          C801.474060,726.125244 812.965881,729.139465 825.264832,730.258057 
          C838.514404,731.463135 851.394958,731.149780 863.781433,726.523132 
          C878.687622,720.955383 889.043396,711.546265 891.257263,694.292419 
          C893.028931,680.485413 888.230896,669.898926 877.708618,662.601990 
          C866.526733,654.847473 852.750793,652.987732 839.629089,649.924255 
          C831.408264,648.005005 823.202332,645.775940 815.290710,642.867371 
          C810.230530,641.007141 808.926392,635.761536 809.800781,630.442993 
          C811.247498,621.643372 819.245422,617.985229 826.728577,617.242981 
          C844.377502,615.492493 861.536621,617.525757 876.610352,629.670898 
          C880.396179,622.847778 884.055359,616.459778 887.429810,609.924805 
          C887.883362,609.046509 887.296875,606.684082 886.519470,606.269409 
          C881.015442,603.332947 875.533691,600.154785 869.673767,598.124451 
          C854.481750,592.860474 838.834106,591.605530 822.860657,593.950745 
          C812.716553,595.440063 803.567383,599.366272 795.892090,605.826843 
          C786.119141,614.053162 782.816345,624.942444 783.800598,637.857178 
          C784.788208,650.815857 791.359131,659.048706 802.107788,665.038513 
          C811.883545,670.486328 822.715393,672.027100 833.454529,674.017822 
          C838.201355,674.897766 842.912476,675.970825 848.492188,676.999451 
        M606.000000,564.500000 
          C606.000000,558.339722 605.753540,552.166138 606.085938,546.023865 
          C606.310242,541.879211 604.328003,540.941772 600.858765,540.984375 
          C595.864746,541.045715 590.863037,541.156006 585.876587,540.952454 
          C581.612793,540.778320 579.871704,542.084778 579.897888,546.784668 
          C580.087952,580.914673 580.000000,615.046143 580.000000,649.177185 
          C580.000000,673.984619 579.999634,698.792053 580.000305,723.599487 
          C580.000488,729.998718 580.002075,729.999023 586.638916,729.999878 
          C590.967712,730.000488 595.327515,729.675720 599.617249,730.085083 
          C604.575928,730.558289 606.140442,729.043579 606.120850,723.834229 
          C605.922852,671.056763 606.000000,618.278198 606.000000,564.500000 
        M1242.000000,549.691833 
          C1241.994629,541.714355 1241.286011,541.000977 1233.364624,541.000061 
          C1229.532593,540.999634 1225.692139,541.159119 1221.870728,540.957581 
          C1217.590454,540.731873 1215.889526,542.112610 1215.901367,546.797852 
          C1216.051392,606.277222 1216.000000,665.757080 1216.000000,725.236816 
          C1216.000000,726.692383 1216.000000,728.148010 1216.000000,730.000000 
          C1222.668945,730.000000 1228.955078,730.000366 1235.241211,729.999878 
          C1241.990601,729.999329 1241.998413,729.998962 1241.998657,723.093262 
          C1242.000854,665.612854 1242.000000,608.132385 1242.000000,549.691833 
        M541.882141,619.001587 
          C547.202454,618.854431 552.522705,618.707275 558.000000,618.555725 
          C558.000000,612.420227 558.000000,607.436768 558.000000,602.453308 
          C558.000000,592.789307 558.000000,592.790161 548.576477,592.999756 
          C538.913086,593.214600 529.701416,595.227722 521.366821,600.284058 
          C517.992249,602.331421 514.880432,604.811707 510.999939,607.553589 
          C510.999939,603.981812 510.808014,601.193604 511.047455,598.442993 
          C511.362366,594.825134 509.791687,593.804810 506.390717,593.942810 
          C501.069641,594.158691 495.711212,594.300171 490.410187,593.909180 
          C485.803040,593.569397 484.901428,595.419373 484.915710,599.642273 
          C485.055573,640.948120 484.997955,682.254578 485.009369,723.560913 
          C485.009735,724.880005 484.851074,726.317200 485.313568,727.482239 
          C485.709991,728.480896 486.874603,729.863708 487.728027,729.884155 
          C495.466522,730.069519 503.211304,729.992737 512.000000,729.992737 
          C512.000000,703.540894 511.601562,677.801025 512.193298,652.083862 
          C512.434998,641.579956 516.285522,631.709839 525.877869,625.765320 
          C530.518433,622.889648 535.914185,621.232605 541.882141,619.001587 
        M981.000000,696.500000 
          C981.000061,706.326416 981.081726,716.153870 980.951538,725.978516 
          C980.911255,729.019714 981.988892,730.140991 985.027710,730.046265 
          C990.352112,729.880249 995.685364,730.000183 1001.014954,729.999939 
          C1007.994751,729.999573 1007.999146,729.999451 1007.999329,722.824524 
          C1008.000549,667.863342 1007.999939,612.902100 1007.999878,557.940918 
          C1007.999878,556.941650 1008.320190,555.782104 1007.921082,554.981506 
          C1007.357971,553.851990 1006.284607,552.163452 1005.390625,552.139099 
          C997.310791,551.919189 989.222534,552.010376 981.000000,552.010376 
          C981.000000,600.260254 981.000000,647.880127 981.000000,696.500000 
        M1557.491211,730.000000 
          C1560.322876,730.000000 1563.163086,729.861816 1565.983887,730.037231 
          C1569.040039,730.227173 1570.049683,728.989014 1570.044922,725.965881 
          C1569.978760,683.324707 1569.979126,640.683350 1570.044678,598.042175 
          C1570.049316,595.028442 1569.056152,593.859009 1565.992798,593.952698 
          C1560.501221,594.120667 1554.982056,594.278931 1549.509888,593.912598 
          C1545.053101,593.614136 1543.889282,595.168945 1543.906982,599.542358 
          C1544.066284,638.851685 1544.000000,678.161865 1544.000000,717.471802 
          C1544.000000,730.000000 1544.000000,730.000000 1557.491211,730.000000 
        M1548.654053,543.894836 
          C1539.190186,548.960205 1537.011719,561.804688 1544.890869,569.819885 
          C1549.637085,574.648132 1559.339966,576.767273 1565.190918,573.273621 
          C1572.831665,568.711243 1576.278442,559.717651 1573.141479,552.528870 
          C1568.975098,542.981079 1561.530396,540.116699 1548.654053,543.894836 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1124.744873,751.999512 
          C1147.622192,746.070435 1150.224609,727.367371 1150.586060,709.040039 
          C1145.494263,712.114685 1140.683228,715.573547 1135.410522,718.048401 
          C1130.110718,720.535706 1124.483032,722.730530 1118.763794,723.827637 
          C1108.911621,725.717407 1098.979126,725.342468 1089.061768,723.101440 
          C1071.131470,719.049744 1056.736450,709.946594 1047.094971,694.182068 
          C1041.403564,684.876099 1037.771606,674.703064 1038.031250,663.556580 
          C1038.203979,656.144226 1037.249878,648.297485 1039.373047,641.442078 
          C1042.027954,632.870483 1046.433105,624.479675 1051.678101,617.152832 
          C1060.442627,604.909363 1073.216553,597.794067 1087.848511,594.697510 
          C1105.306519,591.002930 1122.489746,592.219238 1138.663696,600.367859 
          C1142.751099,602.427124 1146.256104,605.642578 1151.000000,609.017822 
          C1151.000000,604.806030 1151.160522,601.730042 1150.956665,598.678406 
          C1150.734863,595.357422 1151.680420,593.771973 1155.376709,593.932739 
          C1161.197632,594.185913 1167.045654,594.180481 1172.866211,593.923645 
          C1175.758545,593.796082 1176.619263,595.260559 1176.960327,597.542236 
          C1177.130981,598.683655 1176.999512,599.870605 1176.999512,601.036987 
          C1177.000122,633.529236 1177.073242,666.021667 1176.954102,698.513489 
          C1176.919434,707.931396 1177.421753,717.544922 1175.756226,726.719971 
          C1172.924683,742.318542 1167.403931,756.649414 1152.978760,766.183167 
          C1142.888794,772.851807 1132.005981,775.413574 1120.412231,777.096741 
          C1100.958862,779.920898 1082.515625,776.243286 1064.785156,768.817261 
          C1058.376953,766.133240 1052.819458,761.367249 1046.985474,757.387329 
          C1044.581787,755.747559 1044.268433,753.783081 1046.240967,751.119263 
          C1049.584839,746.603699 1052.643921,741.842957 1055.424194,736.958496 
          C1056.876221,734.407654 1057.686890,734.867554 1059.838257,736.185547 
          C1067.458740,740.853943 1074.985962,745.894287 1083.143555,749.414307 
          C1094.890991,754.483398 1107.654053,752.999878 1120.099731,752.954224 
          C1121.505127,752.949036 1122.908081,752.333069 1124.744873,751.999512 
        M1144.036255,682.443604 
          C1150.836182,671.984375 1152.802490,660.572754 1149.913452,648.548340 
          C1147.639771,639.084839 1143.306152,630.673096 1134.658325,625.251221 
          C1128.788818,621.571289 1122.717529,618.906860 1115.636475,617.749390 
          C1100.116211,615.212463 1086.532349,618.542908 1075.255249,629.388123 
          C1064.933472,639.314575 1062.443970,652.283142 1064.206665,665.825745 
          C1065.350098,674.609863 1068.862915,683.136353 1076.006958,688.896545 
          C1089.206177,699.539246 1104.016357,703.949036 1120.914062,698.635376 
          C1130.144287,695.732727 1138.166992,691.354980 1144.036255,682.443604 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M886.250305,202.249695 
          C864.255188,224.245911 842.510254,245.992004 820.765320,267.738098 
          C821.113892,268.281311 821.462463,268.824554 821.811035,269.367767 
          C824.720886,268.044952 827.630676,266.722137 830.540527,265.399353 
          C829.770203,266.108093 828.999878,266.816833 828.229614,267.525574 
          C850.376526,288.403381 873.006897,308.798187 894.509644,330.319611 
          C910.272095,346.095764 899.766052,367.118774 883.945801,371.357086 
          C875.863770,373.522247 867.824524,372.469330 861.290710,365.856842 
          C844.071350,348.430023 826.613159,331.239410 809.372131,313.833862 
          C794.639648,298.960785 780.104431,283.892456 765.408630,268.982758 
          C763.191650,266.733490 760.575317,264.877930 757.856995,262.600006 
          C765.819458,254.653076 772.912720,247.650665 779.921570,240.564743 
          C807.066833,213.121124 834.344299,185.805359 861.233887,158.113113 
          C871.741943,147.291397 894.569763,150.769012 900.298157,167.402130 
          C904.357117,179.188004 899.742432,187.421173 892.832947,195.605652 
          C890.904053,197.890564 888.621033,199.876511 886.250305,202.249695 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1135.250000,301.250061 
          C1147.390625,289.092743 1159.281250,277.185425 1171.215942,265.233795 
          C1174.392822,267.127014 1177.491699,268.973663 1180.590454,270.820343 
          C1180.876709,270.606689 1181.162842,270.393036 1181.449097,270.179382 
          C1180.428101,268.745300 1179.584839,267.134338 1178.359497,265.903748 
          C1160.723267,248.192596 1142.989990,230.577911 1125.382690,212.838211 
          C1118.268677,205.670837 1111.177612,198.456635 1104.486938,190.899933 
          C1097.686890,183.219635 1098.290527,168.727234 1105.651611,159.820816 
          C1114.390259,149.247742 1130.951782,148.452164 1142.353882,159.920883 
          C1170.385864,188.116913 1198.312256,216.417984 1226.197998,244.758789 
          C1231.628296,250.277832 1236.681885,256.167755 1242.313599,262.329071 
          C1238.516724,266.079163 1234.603516,269.869934 1230.771240,273.740845 
          C1200.336182,304.482727 1169.803467,335.129333 1139.588867,366.086548 
          C1133.617432,372.204803 1121.240234,373.525940 1114.064819,370.539276 
          C1106.741577,367.491089 1099.599121,358.383820 1099.826050,349.525055 
          C1100.036255,341.324982 1102.638428,334.780365 1108.374634,329.036255 
          C1117.395508,320.003021 1126.139038,310.692871 1135.250000,301.250061 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M997.997620,205.038330 
          C1003.378418,192.299042 1008.786438,179.980682 1014.046021,167.599274 
          C1018.271912,157.651413 1025.689087,152.514633 1036.446899,151.800781 
          C1048.814575,150.980133 1059.336792,160.092850 1060.993042,173.094467 
          C1062.124878,181.979645 1057.756226,189.402542 1054.434204,197.069122 
          C1042.333862,224.994492 1030.102783,252.863342 1018.049133,280.808777 
          C1006.889832,306.680573 996.178955,332.749817 984.718628,358.486298 
          C978.025330,373.517365 958.367981,377.268311 945.966003,365.178192 
          C938.436340,357.837860 936.428345,347.785156 941.938660,335.103882 
          C954.192444,306.903229 966.135620,278.567749 978.294067,250.325409 
          C984.754028,235.319794 991.399231,220.393921 997.997620,205.038330 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1377.000000,672.000000 
          C1349.069092,672.000000 1321.638184,672.000000 1294.029785,672.000000 
          C1297.518921,686.192810 1304.592896,697.307800 1318.208618,702.453369 
          C1326.384521,705.543152 1334.829956,707.382202 1344.187622,706.353699 
          C1357.126587,704.931641 1367.223145,699.531860 1376.090454,690.599304 
          C1376.911377,689.772278 1379.973389,689.742249 1380.755249,690.546753 
          C1384.496704,694.396851 1387.960449,698.550476 1391.175659,702.855530 
          C1392.022583,703.989563 1392.407227,706.614868 1391.690552,707.595520 
          C1383.492188,718.814331 1371.776978,725.497253 1358.842407,728.674988 
          C1345.534790,731.944397 1331.362671,732.500061 1318.042358,728.738831 
          C1302.363037,724.311462 1289.468384,715.460571 1279.636597,701.628845 
          C1268.755493,686.320801 1266.319092,669.386597 1268.263062,651.923645 
          C1271.017578,627.178345 1284.324219,608.726562 1307.055786,598.132141 
          C1320.720947,591.763306 1335.348755,591.593628 1350.106323,594.485046 
          C1360.895142,596.598877 1370.217407,601.825500 1378.073486,608.892578 
          C1389.495361,619.167236 1397.045898,632.157349 1398.922119,647.749268 
          C1399.726440,654.433594 1399.607422,661.225647 1400.026733,667.961304 
          C1400.215332,670.990356 1399.044556,672.127747 1395.987915,672.047852 
          C1389.828979,671.886841 1383.662964,671.999939 1377.000000,672.000000 
        M1294.969238,647.205627 
          C1293.491699,650.824707 1296.364868,649.951233 1297.991699,649.964417 
          C1307.140015,650.038208 1316.289185,650.000000 1325.438110,650.000000 
          C1340.076294,650.000000 1354.714600,650.019775 1369.352783,649.989075 
          C1374.328125,649.978638 1374.624512,649.179626 1372.931519,644.494995 
          C1370.325684,637.284912 1366.307617,631.249207 1360.631592,626.003113 
          C1349.914429,616.097534 1336.933350,615.059143 1324.020996,617.792297 
          C1308.892944,620.994202 1298.933105,631.022705 1294.969238,647.205627 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M168.996964,680.976807 
          C163.300323,670.020142 160.714920,658.658630 159.968597,646.756226 
          C159.139526,633.533936 160.565414,620.535645 165.502075,608.322388 
          C168.702011,600.405884 172.778809,592.498047 178.055435,585.850647 
          C185.297958,576.726624 193.701187,568.597961 204.293900,562.803467 
          C214.861023,557.022949 225.864822,553.004761 237.619858,551.054993 
          C258.156677,547.648621 277.743896,551.312561 296.385376,560.151855 
          C304.853241,564.167114 312.137329,570.013489 318.347717,577.031738 
          C319.193359,577.987366 319.322784,580.871765 318.537201,581.757751 
          C314.565704,586.236694 310.309967,590.494141 305.836060,594.471741 
          C304.926666,595.280273 301.938354,595.253845 301.116852,594.420715 
          C294.385284,587.593933 286.739685,582.093994 277.593506,579.353821 
          C266.381592,575.994690 254.999130,573.642822 243.054840,575.913269 
          C231.152725,578.175659 220.157562,582.046326 210.584747,589.750244 
          C201.639923,596.948792 195.144470,605.878052 191.056992,616.465210 
          C184.626877,633.120056 185.070831,650.119690 191.417175,666.485291 
          C195.945358,678.162292 204.158432,687.677307 214.612839,694.889343 
          C226.475403,703.072754 239.873688,706.416626 254.073364,705.888977 
          C265.386292,705.468567 276.786621,704.247986 286.664001,697.940857 
          C291.428528,694.898499 295.814728,691.168640 299.990417,687.334290 
          C302.548187,684.985657 304.177979,684.184387 306.812317,687.189148 
          C310.319763,691.189941 314.154419,694.930725 318.086884,698.522278 
          C320.579865,700.799255 320.116608,702.181030 318.041077,704.576111 
          C306.858093,717.480896 292.433533,725.292236 276.207245,728.593140 
          C262.185913,731.445557 247.820221,731.844849 233.254730,729.313477 
          C215.557663,726.237915 200.632843,718.383789 187.583679,706.812439 
          C179.689468,699.812195 173.604416,691.023438 168.996964,680.976807 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M436.225342,610.720459 
          C443.304047,618.079468 445.478119,627.169983 446.900208,636.406372 
          C447.771484,642.065125 447.927277,647.872986 447.954315,653.616028 
          C448.064178,676.941345 447.918579,700.268066 448.068695,723.592957 
          C448.098724,728.263855 447.010162,730.623657 441.731049,730.096313 
          C437.111969,729.634888 432.382599,729.683289 427.752869,730.088196 
          C423.031891,730.501038 421.265411,728.811157 421.970032,724.195618 
          C422.166443,722.908997 422.000000,721.567017 422.000000,719.484009 
          C419.915283,720.374878 418.368286,721.057922 416.804596,721.700256 
          C410.064270,724.469238 403.533844,728.489441 396.523499,729.681824 
          C386.802826,731.335144 376.852203,731.782715 366.818817,729.208435 
          C354.811523,726.127747 344.622559,720.716248 338.105286,710.160706 
          C324.159943,687.574585 334.876862,659.758728 361.865204,651.947815 
          C376.763275,647.636108 391.949463,649.429688 407.039307,649.020386 
          C411.852173,648.889832 416.671600,649.000000 421.615143,649.000000 
          C421.070221,644.593079 420.944122,640.376282 419.977966,636.361694 
          C417.128967,624.523438 407.382233,617.339966 395.042145,616.946167 
          C378.744659,616.426208 363.891235,620.175964 350.982574,630.497742 
          C349.740540,631.490845 348.344025,632.290833 346.526215,633.511536 
          C342.594879,627.189270 338.744415,621.137878 335.127747,614.949829 
          C334.789246,614.370667 335.648956,612.609192 336.421783,611.934509 
          C349.012787,600.942871 363.830902,595.710632 380.358948,593.810669 
          C391.125305,592.573120 401.759094,592.472900 412.018951,595.261963 
          C418.330017,596.977661 424.070068,601.042419 429.814667,604.474060 
          C432.205475,605.902222 433.940643,608.427917 436.225342,610.720459 
        M384.560181,708.000000 
          C386.390564,708.000000 388.221405,708.026855 390.051270,707.995789 
          C407.260864,707.703491 426.065247,694.313599 420.593658,672.000671 
          C404.690308,672.000671 388.978149,671.621094 373.298218,672.166443 
          C365.842163,672.425781 361.165710,677.538086 358.869720,684.629883 
          C356.706909,691.310242 358.365662,696.701904 363.668335,701.407227 
          C369.458099,706.544739 376.198730,708.098572 384.560181,708.000000 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1681.686279,722.804321 
          C1669.761841,729.815002 1656.930542,731.547058 1643.885742,730.877502 
          C1626.456421,729.982849 1611.275024,723.510010 1602.501465,707.672424 
          C1595.189209,694.472717 1596.197998,675.928528 1608.604126,663.610291 
          C1617.062134,655.212158 1626.896729,650.659912 1638.479980,650.089722 
          C1652.872314,649.381226 1667.296875,649.329590 1681.707886,649.004944 
          C1683.338379,648.968201 1684.970459,649.000000 1686.527466,649.000000 
          C1688.637451,634.209534 1683.919312,623.365906 1670.610596,619.199829 
          C1663.789551,617.064636 1655.992920,616.603638 1648.830566,617.319763 
          C1636.310303,618.571655 1624.865723,623.356873 1615.367920,632.059326 
          C1613.347412,633.910767 1612.136475,633.005981 1610.911377,630.767456 
          C1608.444336,626.259766 1606.079590,621.558289 1602.797241,617.677124 
          C1600.062134,614.442993 1601.299072,612.890686 1603.662598,610.809631 
          C1611.178711,604.191711 1619.981323,599.947083 1629.439453,597.042725 
          C1641.291870,593.403076 1653.541382,592.248779 1665.824951,593.093689 
          C1685.863159,594.472046 1702.734985,604.359375 1710.394165,624.102417 
          C1712.630737,629.867737 1713.759521,636.401917 1713.843506,642.604736 
          C1714.208374,669.589905 1713.929199,696.583252 1714.067627,723.572632 
          C1714.091431,728.223022 1713.059937,730.617676 1707.751831,730.098694 
          C1702.966064,729.630798 1698.079468,729.736389 1693.272339,730.078308 
          C1688.946777,730.385925 1687.816772,728.321472 1687.986084,724.597107 
          C1688.067139,722.815918 1688.000000,721.027954 1688.000000,718.336792 
          C1685.552490,720.045715 1683.762451,721.295532 1681.686279,722.804321 
        M1686.942749,671.743347 
          C1686.705078,671.514160 1686.463623,671.080872 1686.230103,671.085022 
          C1670.322021,671.370300 1654.399292,671.375671 1638.513672,672.145630 
          C1630.948608,672.512390 1623.936646,681.541565 1624.000000,689.240906 
          C1624.068604,697.585754 1631.221069,706.108765 1639.881714,706.857971 
          C1647.633545,707.528503 1655.551147,707.626831 1663.284912,706.859924 
          C1676.302368,705.569092 1687.000244,693.061707 1686.999756,680.207520 
          C1686.999634,677.707947 1686.997314,675.208435 1686.942749,671.743347 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M650.742065,613.757202 
          C667.130005,596.206116 687.685852,591.193420 710.247192,593.141785 
          C725.781006,594.483276 739.422424,601.076477 750.526428,612.119629 
          C762.170105,623.699585 768.446228,637.860229 770.073181,654.290466 
          C771.852966,672.263428 767.747253,688.588562 757.270447,703.162903 
          C750.732727,712.257629 742.248779,718.994751 732.100708,724.244995 
          C721.512268,729.723083 710.389893,730.979919 699.126465,730.815369 
          C682.545166,730.573181 667.639343,725.475220 655.007446,713.767761 
          C645.061279,704.549622 637.795227,693.940002 634.644775,680.977112 
          C631.914856,669.744263 631.143555,658.372253 633.743591,646.617310 
          C636.523254,634.050232 641.396484,623.081848 650.742065,613.757202 
        M721.400574,702.065613 
          C729.198364,698.164185 734.982300,692.225708 738.839600,684.462341 
          C744.326111,673.419922 745.586670,661.633362 742.770752,649.843689 
          C740.042542,638.421021 734.321106,628.668640 723.354431,622.679321 
          C714.608093,617.902649 705.269775,615.680969 695.686951,617.191223 
          C683.338440,619.137329 672.452759,624.191284 665.567932,635.772400 
          C659.821167,645.439331 658.293396,655.737793 658.987122,666.664917 
          C659.976562,682.250244 667.116516,694.339111 680.689026,701.761108 
          C693.638916,708.842712 707.353760,707.856873 721.400574,702.065613 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1471.841553,707.000000 
          C1477.155884,705.883789 1482.239380,705.369446 1486.770508,703.509583 
          C1493.488525,700.752136 1496.662109,694.212463 1494.891235,687.279724 
          C1493.315430,681.110291 1487.550659,679.343872 1482.319336,678.115234 
          C1469.479248,675.099792 1456.341431,673.263611 1443.625488,669.833008 
          C1430.006836,666.158936 1418.031372,658.855591 1414.391113,644.303406 
          C1410.185791,627.492004 1415.364014,610.324524 1433.434448,600.768066 
          C1443.010620,595.703796 1452.906616,593.478516 1463.536255,592.941467 
          C1480.314331,592.093811 1495.975220,596.082825 1511.056274,603.011536 
          C1512.080566,603.482056 1513.037598,604.102783 1514.071045,604.549194 
          C1518.490234,606.458496 1518.451660,609.153442 1516.072144,612.906982 
          C1512.737305,618.167664 1509.829468,623.699036 1506.554321,629.438354 
          C1497.941162,623.317627 1488.896973,618.345154 1478.743286,617.535583 
          C1467.351196,616.627258 1455.430420,614.518921 1444.981323,621.997070 
          C1437.242188,627.535767 1437.006348,639.674805 1445.993774,643.309204 
          C1453.207764,646.226501 1460.859375,648.282043 1468.491211,649.895569 
          C1481.219360,652.586365 1494.146484,654.752136 1505.533936,661.330322 
          C1514.300171,666.394348 1519.828491,673.992981 1521.108154,684.645813 
          C1523.082031,701.078247 1516.908936,713.506042 1503.263550,721.988281 
          C1495.786499,726.636108 1487.456177,729.873779 1478.284180,730.042908 
          C1473.032593,730.139771 1467.768799,731.136719 1462.544922,730.915283 
          C1443.917114,730.125610 1426.178833,726.159363 1410.709106,714.970337 
          C1408.140747,713.112610 1406.923584,711.359436 1409.252075,708.067078 
          C1412.389160,703.631409 1414.654907,698.586121 1417.673340,694.054688 
          C1418.348145,693.041687 1421.298340,692.192810 1422.012329,692.761292 
          C1431.992432,700.707947 1444.067993,703.643372 1455.932495,706.728455 
          C1460.772583,707.987061 1466.202271,706.977600 1471.841553,707.000000 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1806.171875,677.980774 
          C1799.620239,676.642822 1793.491089,675.305603 1787.354492,674.003174 
          C1773.363037,671.033691 1758.849609,669.267639 1748.023682,658.452026 
          C1742.508423,652.941833 1739.367920,646.617188 1738.721313,638.286621 
          C1737.701538,625.148621 1741.060913,614.125183 1750.954468,605.773315 
          C1758.613159,599.308350 1767.791138,595.442871 1777.936523,593.940308 
          C1793.918579,591.573242 1809.548096,592.929443 1824.741577,598.130859 
          C1830.454956,600.086853 1835.796875,603.212891 1841.113892,606.156372 
          C1841.982544,606.637207 1842.564575,609.300964 1842.047607,610.347290 
          C1838.923340,616.669983 1835.441040,622.815735 1832.329712,628.556946 
          C1824.377930,625.199402 1817.140015,621.420044 1809.464233,619.073486 
          C1798.197998,615.629150 1786.542847,615.488403 1775.222046,619.095886 
          C1766.583862,621.848511 1761.395630,630.759460 1765.905640,638.710876 
          C1768.972412,644.118103 1774.429810,645.578308 1779.610229,646.880981 
          C1795.148926,650.788452 1811.331909,652.303162 1826.098999,659.037598 
          C1836.796753,663.916321 1844.251709,671.485168 1846.090210,683.871216 
          C1847.940308,696.335571 1844.851196,706.914734 1836.026489,715.879150 
          C1828.934204,723.083618 1820.195923,727.272034 1810.414795,728.884460 
          C1802.851807,730.131165 1795.081421,731.161072 1787.465088,730.836365 
          C1768.700806,730.036438 1750.652222,726.452942 1735.345703,714.241333 
          C1733.177856,712.511902 1732.519043,711.671265 1733.932251,709.283569 
          C1737.040161,704.032715 1739.814697,698.580017 1743.058350,693.419067 
          C1743.548340,692.639465 1746.327271,692.288269 1747.204224,692.916321 
          C1757.470337,700.268494 1769.088501,704.660217 1781.300171,706.453918 
          C1791.392944,707.936462 1801.947144,708.477051 1811.703369,703.697998 
          C1817.435303,700.890198 1821.711426,694.269348 1820.933105,689.687195 
          C1819.892212,683.559448 1813.939575,678.707642 1806.171875,677.980774 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M848.065979,676.977783 
          C842.912476,675.970825 838.201355,674.897766 833.454529,674.017822 
          C822.715393,672.027100 811.883545,670.486328 802.107788,665.038513 
          C791.359131,659.048706 784.788208,650.815857 783.800598,637.857178 
          C782.816345,624.942444 786.119141,614.053162 795.892090,605.826843 
          C803.567383,599.366272 812.716553,595.440063 822.860657,593.950745 
          C838.834106,591.605530 854.481750,592.860474 869.673767,598.124451 
          C875.533691,600.154785 881.015442,603.332947 886.519470,606.269409 
          C887.296875,606.684082 887.883362,609.046509 887.429810,609.924805 
          C884.055359,616.459778 880.396179,622.847778 876.610352,629.670898 
          C861.536621,617.525757 844.377502,615.492493 826.728577,617.242981 
          C819.245422,617.985229 811.247498,621.643372 809.800781,630.442993 
          C808.926392,635.761536 810.230530,641.007141 815.290710,642.867371 
          C823.202332,645.775940 831.408264,648.005005 839.629089,649.924255 
          C852.750793,652.987732 866.526733,654.847473 877.708618,662.601990 
          C888.230896,669.898926 893.028931,680.485413 891.257263,694.292419 
          C889.043396,711.546265 878.687622,720.955383 863.781433,726.523132 
          C851.394958,731.149780 838.514404,731.463135 825.264832,730.258057 
          C812.965881,729.139465 801.474060,726.125244 790.498291,720.901062 
          C786.336548,718.920288 782.600403,715.975342 778.898987,713.168213 
          C778.304199,712.717102 778.488708,710.459595 779.034668,709.413940 
          C781.857849,704.006531 784.789185,698.642456 788.023743,693.476685 
          C788.526672,692.673462 791.306213,692.254150 792.157471,692.869141 
          C802.402405,700.271057 814.036377,704.528320 826.241150,706.472229 
          C836.557861,708.115540 847.194580,707.953369 857.220093,703.891357 
          C861.654053,702.094849 866.568848,693.837646 865.970642,689.801575 
          C865.187500,684.517029 859.214966,679.010437 853.212952,677.991577 
          C851.628845,677.722778 850.065247,677.333435 848.065979,676.977783 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M606.000000,565.000000 
          C606.000000,618.278198 605.922852,671.056763 606.120850,723.834229 
          C606.140442,729.043579 604.575928,730.558289 599.617249,730.085083 
          C595.327515,729.675720 590.967712,730.000488 586.638916,729.999878 
          C580.002075,729.999023 580.000488,729.998718 580.000305,723.599487 
          C579.999634,698.792053 580.000000,673.984619 580.000000,649.177185 
          C580.000000,615.046143 580.087952,580.914673 579.897888,546.784668 
          C579.871704,542.084778 581.612793,540.778320 585.876587,540.952454 
          C590.863037,541.156006 595.864746,541.045715 600.858765,540.984375 
          C604.328003,540.941772 606.310242,541.879211 606.085938,546.023865 
          C605.753540,552.166138 606.000000,558.339722 606.000000,565.000000 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1242.000000,550.171875 
          C1242.000000,608.132385 1242.000854,665.612854 1241.998657,723.093262 
          C1241.998413,729.998962 1241.990601,729.999329 1235.241211,729.999878 
          C1228.955078,730.000366 1222.668945,730.000000 1216.000000,730.000000 
          C1216.000000,728.148010 1216.000000,726.692383 1216.000000,725.236816 
          C1216.000000,665.757080 1216.051392,606.277222 1215.901367,546.797852 
          C1215.889526,542.112610 1217.590454,540.731873 1221.870728,540.957581 
          C1225.692139,541.159119 1229.532593,540.999634 1233.364624,541.000061 
          C1241.286011,541.000977 1241.994629,541.714355 1242.000000,550.171875 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M541.425049,619.012695 
          C535.914185,621.232605 530.518433,622.889648 525.877869,625.765320 
          C516.285522,631.709839 512.434998,641.579956 512.193298,652.083862 
          C511.601562,677.801025 512.000000,703.540894 512.000000,729.992737 
          C503.211304,729.992737 495.466522,730.069519 487.728027,729.884155 
          C486.874603,729.863708 485.709991,728.480896 485.313568,727.482239 
          C484.851074,726.317200 485.009735,724.880005 485.009369,723.560913 
          C484.997955,682.254578 485.055573,640.948120 484.915710,599.642273 
          C484.901428,595.419373 485.803040,593.569397 490.410187,593.909180 
          C495.711212,594.300171 501.069641,594.158691 506.390717,593.942810 
          C509.791687,593.804810 511.362366,594.825134 511.047455,598.442993 
          C510.808014,601.193604 510.999939,603.981812 510.999939,607.553589 
          C514.880432,604.811707 517.992249,602.331421 521.366821,600.284058 
          C529.701416,595.227722 538.913086,593.214600 548.576477,592.999756 
          C558.000000,592.790161 558.000000,592.789307 558.000000,602.453308 
          C558.000000,607.436768 558.000000,612.420227 558.000000,618.555725 
          C552.522705,618.707275 547.202454,618.854431 541.425049,619.012695 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M981.000000,696.000000 
          C981.000000,647.880127 981.000000,600.260254 981.000000,552.010376 
          C989.222534,552.010376 997.310791,551.919189 1005.390625,552.139099 
          C1006.284607,552.163452 1007.357971,553.851990 1007.921082,554.981506 
          C1008.320190,555.782104 1007.999878,556.941650 1007.999878,557.940918 
          C1007.999939,612.902100 1008.000549,667.863342 1007.999329,722.824524 
          C1007.999146,729.999451 1007.994751,729.999573 1001.014954,729.999939 
          C995.685364,730.000183 990.352112,729.880249 985.027710,730.046265 
          C981.988892,730.140991 980.911255,729.019714 980.951538,725.978516 
          C981.081726,716.153870 981.000061,706.326416 981.000000,696.000000 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1557.000366,730.000000 
          C1544.000000,730.000000 1544.000000,730.000000 1544.000000,717.471802 
          C1544.000000,678.161865 1544.066284,638.851685 1543.906982,599.542358 
          C1543.889282,595.168945 1545.053101,593.614136 1549.509888,593.912598 
          C1554.982056,594.278931 1560.501221,594.120667 1565.992798,593.952698 
          C1569.056152,593.859009 1570.049316,595.028442 1570.044678,598.042175 
          C1569.979126,640.683350 1569.978760,683.324707 1570.044922,725.965881 
          C1570.049683,728.989014 1569.040039,730.227173 1565.983887,730.037231 
          C1563.163086,729.861816 1560.322876,730.000000 1557.000366,730.000000 
        z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
        M1548.909424,543.599243 
          C1561.530396,540.116699 1568.975098,542.981079 1573.141479,552.528870 
          C1576.278442,559.717651 1572.831665,568.711243 1565.190918,573.273621 
          C1559.339966,576.767273 1549.637085,574.648132 1544.890869,569.819885 
          C1537.011719,561.804688 1539.190186,548.960205 1548.909424,543.599243 
        z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
        M1143.780762,682.715515 
          C1138.166992,691.354980 1130.144287,695.732727 1120.914062,698.635376 
          C1104.016357,703.949036 1089.206177,699.539246 1076.006958,688.896545 
          C1068.862915,683.136353 1065.350098,674.609863 1064.206665,665.825745 
          C1062.443970,652.283142 1064.933472,639.314575 1075.255249,629.388123 
          C1086.532349,618.542908 1100.116211,615.212463 1115.636475,617.749390 
          C1122.717529,618.906860 1128.788818,621.571289 1134.658325,625.251221 
          C1143.306152,630.673096 1147.639771,639.084839 1149.913452,648.548340 
          C1152.802490,660.572754 1150.836182,671.984375 1143.780762,682.715515 
        z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
        M1294.984985,646.795654 
          C1298.933105,631.022705 1308.892944,620.994202 1324.020996,617.792297 
          C1336.933350,615.059143 1349.914429,616.097534 1360.631592,626.003113 
          C1366.307617,631.249207 1370.325684,637.284912 1372.931519,644.494995 
          C1374.624512,649.179626 1374.328125,649.978638 1369.352783,649.989075 
          C1354.714600,650.019775 1340.076294,650.000000 1325.438110,650.000000 
          C1316.289185,650.000000 1307.140015,650.038208 1297.991699,649.964417 
          C1296.364868,649.951233 1293.491699,650.824707 1294.984985,646.795654 
        z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
        M384.080170,708.000000 
          C376.198730,708.098572 369.458099,706.544739 363.668335,701.407227 
          C358.365662,696.701904 356.706909,691.310242 358.869720,684.629883 
          C361.165710,677.538086 365.842163,672.425781 373.298218,672.166443 
          C388.978149,671.621094 404.690308,672.000671 420.593658,672.000671 
          C426.065247,694.313599 407.260864,707.703491 390.051270,707.995789 
          C388.221405,708.026855 386.390564,708.000000 384.080170,708.000000 
        z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
        M1686.969360,672.226074 
          C1686.997314,675.208435 1686.999634,677.707947 1686.999756,680.207520 
          C1687.000244,693.061707 1676.302368,705.569092 1663.284912,706.859924 
          C1655.551147,707.626831 1647.633545,707.528503 1639.881714,706.857971 
          C1631.221069,706.108765 1624.068604,697.585754 1624.000000,689.240906 
          C1623.936646,681.541565 1630.948608,672.512390 1638.513672,672.145630 
          C1654.399292,671.375671 1670.322021,671.370300 1686.230103,671.085022 
          C1686.463623,671.080872 1686.705078,671.514160 1686.969360,672.226074 
        z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
        M721.138672,702.358032 
          C707.353760,707.856873 693.638916,708.842712 680.689026,701.761108 
          C667.116516,694.339111 659.976562,682.250244 658.987122,666.664917 
          C658.293396,655.737793 659.821167,645.439331 665.567932,635.772400 
          C672.452759,624.191284 683.338440,619.137329 695.686951,617.191223 
          C705.269775,615.680969 714.608093,617.902649 723.354431,622.679321 
          C734.321106,628.668640 740.042542,638.421021 742.770752,649.843689 
          C745.586670,661.633362 744.326111,673.419922 738.839600,684.462341 
          C734.982300,692.225708 729.198364,698.164185 721.138672,702.358032 
        z"
        />
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html: `

      .signature1{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.5s;
			-o-animation: dash 1s linear forwards 0.5s;
			-moz-animation: dash 1s linear forwards 0.5s;
			animation: dash 1s linear forwards 0.5s;
		}
		.signature2{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.7s;
			-o-animation: dash 1s linear forwards 0.7s;
			-moz-animation: dash 1s linear forwards 0.7s;
			animation: dash 1s linear forwards 0.7s;
		}
		.signature3{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.9s;
			-o-animation: dash 1s linear forwards 0.9s;
			-moz-animation: dash 1s linear forwards 0.9s;
			animation: dash 1s linear forwards 0.9s;
		}
		.signature4{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.1s;
			-o-animation: dash 1s linear forwards 1.1s;
			-moz-animation: dash 1s linear forwards 1.1s;
			animation: dash 1s linear forwards 1.1s;
		}
		.signature5{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.3s;
			-o-animation: dash 1s linear forwards 1.3s;
			-moz-animation: dash 1s linear forwards 1.3s;
			animation: dash 1s linear forwards 1.3s;
		}
		.signature6{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.5s;
			-o-animation: dash 1s linear forwards 1.5s;
			-moz-animation: dash 1s linear forwards 1.5s;
			animation: dash 1s linear forwards 1.5s;
		}
		.signature7{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.7s;
			-o-animation: dash 1s linear forwards 1.7s;
			-moz-animation: dash 1s linear forwards 1.7s;
			animation: dash 1s linear forwards 1.7s;
		}
		.signature8{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.9s;
			-o-animation: dash 1s linear forwards 1.9s;
			-moz-animation: dash 1s linear forwards 1.9s;
			animation: dash 1s linear forwards 1.9s;
		}
		.signature9{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.1s;
			-o-animation: dash 1s linear forwards 2.1s;
			-moz-animation: dash 1s linear forwards 2.1s;
			animation: dash 1s linear forwards 2.1s;
		}
		.signature10{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.3s;
			-o-animation: dash 1s linear forwards 2.3s;
			-moz-animation: dash 1s linear forwards 2.3s;
			animation: dash 1s linear forwards 2.3s;
		}
		.signature11{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.5s;
			-o-animation: dash 1s linear forwards 2.5s;
			-moz-animation: dash 1s linear forwards 2.5s;
			animation: dash 1s linear forwards 2.5s;
		}
		.signature12{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.7s;
			-o-animation: dash 1s linear forwards 2.7s;
			-moz-animation: dash 1s linear forwards 2.7s;
			animation: dash 1s linear forwards 2.7s;
		}
		.signature13{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.9s;
			-o-animation: dash 1s linear forwards 2.9s;
			-moz-animation: dash 1s linear forwards 2.9s;
			animation: dash 1s linear forwards 2.9s;
		}
		.signature14{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 3.1s;
			-o-animation: dash 1s linear forwards 3.1s;
			-moz-animation: dash 1s linear forwards 3.1s;
			animation: dash 1s linear forwards 3.1s;
		}
		.signature15{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 3.3s;
			-o-animation: dash 1s linear forwards 3.3s;
			-moz-animation: dash 1s linear forwards 3.3s;
			animation: dash 1s linear forwards 3.3s;
		}

		.signature{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 6s linear forwards 0.5s;
			-o-animation: dash 6s linear forwards 0.5s;
			-moz-animation: dash 6s linear forwards 0.5s;
			animation: dash 6s linear forwards 0.5s;
		}

		.letter{
			opacity: 0;
			-webkit-animation: fadein 2s linear forwards 2.5s;
			-o-animation: fadein 2s linear forwards 2.5s;
			-moz-animation: fadein 2s linear forwards 2.5s;
			animation: fadein 2s linear forwards 2.5s;
		}

		@-webkit-keyframes fadein{
			from{
				opacity: 0;
			}
			to{
				opacity: 1;
			}
		}

      .myHexagon{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 4s linear forwards 0.5s;
			-o-animation: dash 4s linear forwards 0.5s;
			-moz-animation: dash 4s linear forwards 0.5s;
			animation: dash 4s linear forwards 0.5s;
		}

		@-webkit-keyframes dash{
			from{
				stroke-dashoffset: 800;
			}
			to{
				stroke-dashoffset: 0;
			}
		}
    `,
            }}
          />
        </defs>
      </svg>
    );
  }
}

export default LogoLoader;
